import i18next from "i18next";

export interface RoomRecord {
    date: LangSet;
    start: number;
    notice: number;
    end: number;
    has_password: boolean;
    type: string;
    title: LangSet;
    name: LangSet;
    account_id: string;
}

export interface LangSet {
    en: string;
    jp: string;
}

export class Message {
  message: string;
  date: string;
  name: string;

  constructor(_message: string, _date: string, _name: string){
    this.message = _message;
    this.date = _date;
    this.name = _name;
  }
}

export function getValue(item: LangSet | undefined){
    if(item === undefined)
        return '';
    
    if(i18next.language.toLowerCase().startsWith('ja'))
        return item?.jp;
    else
        return item?.en;
}