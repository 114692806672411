import React, { useState, ChangeEvent, FormEvent } from 'react';
import { Typography, makeStyles, TextField, Grid, Button, InputLabel, Theme } from '@material-ui/core';
import { useAppState } from '../../../state';
import '../../../i18n/config';
import { useTranslation } from 'react-i18next';
import { Steps } from '../PreJoinScreens';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1.5em 0 1.5em',
    '& div:not(:last-child)': {
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '1.5em 0 2em',
    },
  },
  textFieldContainer: {
    width: '100%',
  },
  continueButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  errorMessage: {
    color: 'red',
    display: 'flex',
    alignItems: 'center',
    margin: '1em 0 0.2em',
    '& svg': {
      marginRight: '0.4em',
    },
  },
}));

interface PasswordScreenProps {
  roomName: string;
  setStep: (step: Steps) => void;
}

export default function PasswordScreen({ roomName, setStep }: PasswordScreenProps) {
  const classes = useStyles();
  const { checkPassword, setRoomRecord } = useAppState();

  const { t } = useTranslation();

  const [password, setPassword] = useState<string>('');
  const [isPass, SetIsPass] = useState<boolean>(true);

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    checkPassword(roomName, password).then(
        result => {
            if(result){
                setRoomRecord(result);

                if(result.end <= 0)
                  setStep(Steps.notFoundStep);
                else {
                  if(result.start > 0)
                    setStep(Steps.watingRoomStep);
                  else
                    setStep(Steps.roomNameStep);
                }
            }
        }
    ).catch((error) => {
        SetIsPass(false);
    });
  };

  return (
    <>
      <Typography variant="h5" className={classes.gutterBottom}>
      {t('PasswordScreen.join_room')}
      </Typography>
      <Typography variant="body1">
        {t('PasswordScreen.enter_password')}
      </Typography>
      <form onSubmit={handleSubmit}>
        <div className={classes.inputContainer}>
        <div className={classes.textFieldContainer}>
              <InputLabel shrink htmlFor="input-user-name">
              {t('PasswordScreen.room_password')}
              </InputLabel>
              <TextField
                id="input-user-name"
                variant="outlined"
                fullWidth
                size="small"
                value={password}
                onChange={handlePasswordChange}
              />
            </div>
        </div>
        <div>
            {
            !isPass && (
                <Typography variant="caption" className={classes.errorMessage}>
                <ErrorOutlineIcon />
                {t('PasswordScreen.error_wrong_password')}
            </Typography>
            )
            }
        </div>
        <Grid container justify="flex-end">
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={!password}
            className={classes.continueButton}
          >
            {t('PasswordScreen.button_next')}
          </Button>
        </Grid>
      </form>
    </>
  );
}