import React from 'react';
import { Typography, makeStyles, Theme, Grid } from '@material-ui/core';
import '../../../i18n/config';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
    section: {
        width: '100%',
        height: '100%'
      }
}));

export default function LoadingScreen(){
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Grid container alignItems="center" justify="center" className={classes.section}>
            <Grid item xs={3} component={Typography} variant="h5">
            {t('LoadingScreen.loading')}
            </Grid>
        </Grid>
    );
}