import React, { useEffect } from 'react';
import ParticipantList from '../ParticipantList/ParticipantList';
import { styled } from '@material-ui/core/styles';
import MainParticipant from '../MainParticipant/MainParticipant';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { useAppState } from '../../state';
import '../../i18n/config';
import { useTranslation } from 'react-i18next';

const Container = styled('div')(({ theme }) => {
  const totalMobileSidebarHeight = `${theme.sidebarMobileHeight +
    theme.sidebarMobilePadding * 2 +
    theme.participantBorderWidth}px`;

  return {
    position: 'relative',
    height: '100%',
    display: 'grid',
    gridTemplateColumns: `1fr ${theme.sidebarWidth}px`,
    gridTemplateRows: '100%',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: `100%`,
      gridTemplateRows: `calc(100% - ${totalMobileSidebarHeight}) ${totalMobileSidebarHeight}`,
    },
  };
});

export default function Room() {
  const { room } = useVideoContext();
  const { roomRecord, setIsEnd, setMessage } = useAppState();
  const { t } = useTranslation();

  useEffect(() => {
    const timer_end = setTimeout(() => {
      setIsEnd(true);
      room.disconnect();
    }, roomRecord?.end * 1000);

    let timer_notice: NodeJS.Timeout;

    if(roomRecord.notice !== roomRecord.end){
      let notice = Math.floor((roomRecord.end - roomRecord.notice) / 60);
      timer_notice = setTimeout(() => {
        setMessage(t('Room.message_notice', {notice: notice}));
      }, roomRecord?.notice * 1000);
    }
    
    return () => {
      clearTimeout(timer_end);
      if(timer_notice !== undefined)
        clearTimeout(timer_notice);
    }
  }, []);

  return (
    <Container>
      <MainParticipant />
      <ParticipantList />
    </Container>
  );
}
