import React from 'react';
import Video from 'twilio-video';
import { Container, Link, Typography, Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import '../../i18n/config';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  container: {
    marginTop: '2.5em',
  },
  paper: {
    padding: '1em',
  },
  heading: {
    marginBottom: '0.4em',
  },
});

export default function UnsupportedBrowserWarning({ children }: { children: React.ReactElement }) {
  const classes = useStyles();

  const { t } = useTranslation();

  if (!Video.isSupported) {
    return (
      <Container>
        <Grid container justify="center" className={classes.container}>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>
              <Typography variant="h4" className={classes.heading}>
                {t('UnsupportedBrowserWarning.not_support')}
              </Typography>
              <Typography>
              {t('UnsupportedBrowserWarning.open_application')}{' '}
                <Link
                  href="https://www.twilio.com/docs/video/javascript#supported-browsers"
                  target="_blank"
                  rel="noopener"
                >
                  {t('UnsupportedBrowserWarning.supported_broswers')}
                </Link>
                .
                <br />
                {t('UnsupportedBrowserWarning.security')}{' '}
                <Link
                  href="https://developer.mozilla.org/en-US/docs/Web/Security/Secure_Contexts"
                  target="_blank"
                  rel="noopener"
                >
                  {t('UnsupportedBrowserWarning.security_content')}
                </Link>{' '}
                {t('UnsupportedBrowserWarning.security_example')}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    );
  }

  return children;
}
