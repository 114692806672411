import React from 'react';
import { Typography, makeStyles, Theme } from '@material-ui/core';
import '../../../i18n/config';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
    gutterBottom: {
        marginBottom: '1em',
      },
}));

export default function NotFoundScreen(){
    const classes = useStyles();
    const { t } = useTranslation();

    return (
    <>
        <Typography variant="h5" className={classes.gutterBottom}>
            {t('NotFoundScreen.error')}
        </Typography>
        <Typography variant="body1" className={classes.gutterBottom}>
            {t('NotFoundScreen.not_found')}
        </Typography>
    </>
    );
}