import React from 'react';
import ReactDOM from 'react-dom';

import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

import App from './App';
import AppStateProvider, { useAppState } from './state';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import ErrorDialog from './components/ErrorDialog/ErrorDialog';
import MessageDialog from './components/MessageDialog/MessageDialog';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import theme from './theme';
import './types';
import { VideoProvider } from './components/VideoProvider';
import useConnectionOptions from './utils/useConnectionOptions/useConnectionOptions';
import UnsupportedBrowserWarning from './components/UnsupportedBrowserWarning/UnsupportedBrowserWarning';

const VideoApp = () => {
  const { error, setError, message, setMessage } = useAppState();
  const connectionOptions = useConnectionOptions();

  return (
    <UnsupportedBrowserWarning>
      <VideoProvider options={connectionOptions} onError={setError}>
        <ErrorDialog dismissError={() => setError(null)} error={error} />
        <MessageDialog dismissMessage={() => setMessage('')} message={message} />
        <App />
      </VideoProvider>
    </UnsupportedBrowserWarning>
  );
};

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <Router>
      
        <Switch>
          {/* <PrivateRoute exact path="/">
            <VideoApp />
          </PrivateRoute> */}
          <PrivateRoute path="/room/:URLRoomName/:URLRef?">
            <AppStateProvider>
            <VideoApp />
            </AppStateProvider>
          </PrivateRoute>
          {/* <Route path="/login">
            <LoginPage />
          </Route>
          <Redirect to="/" /> */}
        </Switch>
      
    </Router>
  </MuiThemeProvider>,
  document.getElementById('root')
);
