import React from 'react';
import Snackbar from '../Snackbar/Snackbar';
import useRoomState from '../../hooks/useRoomState/useRoomState';

import '../../i18n/config';
import { useTranslation } from 'react-i18next';

export default function ReconnectingNotification() {
  const roomState = useRoomState();

  const { t } = useTranslation();

  return (
    <Snackbar
      variant={t('ReconnectingNotification.variant')}
      headline={t('ReconnectingNotification.headline')}
      message={t('ReconnectingNotification.message')}
      open={roomState === 'reconnecting'}
    />
  );
}
