import React, { PropsWithChildren } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';

import '../../i18n/config';
import { useTranslation } from 'react-i18next';

interface MessageDialogProps {
  dismissMessage: Function;
  message: string;
}

function MessageDialog({ dismissMessage, message }: PropsWithChildren<MessageDialogProps>) {

  const { t } = useTranslation();

  return (
    <Dialog open={message !== ''} onClose={() => dismissMessage()} fullWidth={true} maxWidth="xs">
      <DialogTitle>{t('MessageDialog.message')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dismissMessage()} color="primary" autoFocus>
        {t('MessageDialog.button_ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default MessageDialog;
