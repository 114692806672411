import React, { useState, useEffect, FormEvent } from 'react';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import IntroContainer from '../IntroContainer/IntroContainer';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import PreflightTest from './PreflightTest/PreflightTest';
import RoomNameScreen from './RoomNameScreen/RoomNameScreen';
import LoadingScreen from './LoadingScreen/LoadingScreen';
import WaitingRoomScreen from './WaitingRoomScreen/WaitingRoomScreen';
import PasswordScreen from './PasswordScreen/PasswordScreen';
import NotFoundScreen from './NotFoundScreen/NotFoundScreen';
import EndScreen from './EndScreen/EndScreen';
import { useAppState } from '../../state';
import { useParams } from 'react-router-dom';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import Video from 'twilio-video';
import { getValue } from '../../state/settings/roomRecord';
import '../../i18n/config';
import { useTranslation } from 'react-i18next';

export enum Steps {
  loadingStep,
  watingRoomStep,
  passwordStep,
  roomNameStep,
  deviceSelectionStep,
  notFoundStep,
  endStep
}

export default function PreJoinScreens() {
  const { user, getRoom, setRoomRecord, isEnd } = useAppState();
  const { getAudioAndVideoTracks } = useVideoContext();
  const { URLRoomName, URLRef } = useParams();
  const [step, setStep] = useState(Steps.loadingStep);

  const [name, setName] = useState<string>(user?.displayName || '');
  const [roomName, setRoomName] = useState<string>('');
  const [refId, setRefId] = useState<string>('');

  const [mediaError, setMediaError] = useState<Error>();

  const { t } = useTranslation();

  useEffect(() => {
    if (URLRoomName) {
      setRoomName(URLRoomName);
    }
    if (URLRef) {
      setRefId(URLRef);
    }
  }, [user, URLRoomName, URLRef]);

  useEffect(() => {
    document.title = t('Browser.title');
    
    if(isEnd){
      setStep(Steps.endStep);
    }
    else{
      if(URLRoomName !== undefined){
        getRoom(URLRoomName, URLRef || '').then(
          result => {
            setRoomRecord(result);
  
            let title = getValue(result.title);
            
            if(title === '')
              document.title = t('Browser.title');
            else
              document.title = title;
  
            if(result.end <= 0)
              setStep(Steps.notFoundStep);
            else {
              if(result.has_password)
                setStep(Steps.passwordStep);
              else{
                if(result.start > 0)
                  setStep(Steps.watingRoomStep);
                else
                  setStep(Steps.roomNameStep);
              }
            }
          }
        ).catch((error) => {
          setStep(Steps.notFoundStep);
        });
      }
      else{
        setStep(Steps.notFoundStep);
      }
    }
  }, []);

  useEffect(() => {
    if (step === Steps.deviceSelectionStep && !mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.log('Error acquiring local media:');
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, step, mediaError]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // If this app is deployed as a twilio function, don't change the URL because routing isn't supported.
    if (!window.location.origin.includes('twil.io')) {
      window.history.replaceState(null, '', window.encodeURI(`/room/${roomName}/${refId}${window.location.search || ''}`));
    }
    setStep(Steps.deviceSelectionStep);
  };

  const SubContent = (
    <>
      {Video.testPreflight && <PreflightTest />}
      <MediaErrorSnackbar error={mediaError} />
    </>
  );

  return (
    <IntroContainer subContent={step === Steps.deviceSelectionStep && SubContent}>
      {step === Steps.loadingStep && (
        <LoadingScreen />
      )}

      {step === Steps.passwordStep && (
        <PasswordScreen
          roomName={roomName}
          setStep={setStep}
        />
      )}

      {step === Steps.watingRoomStep && (
        <WaitingRoomScreen 
        setStep={setStep}
        />
      )}

      {step === Steps.roomNameStep && (
        <RoomNameScreen
          name={name}
          roomName={roomName}
          setName={setName}
          setRoomName={setRoomName}
          handleSubmit={handleSubmit}
        />
      )}

      {step === Steps.deviceSelectionStep && (
        <DeviceSelectionScreen name={name} roomName={roomName} refId={refId} setStep={setStep} />
      )}

      {step === Steps.notFoundStep && (
        <NotFoundScreen />
      )}

      {step === Steps.endStep && (
        <EndScreen />
      )}
    </IntroContainer>
  );
}
