import React, { useEffect } from 'react';
import { Typography, makeStyles, Theme } from '@material-ui/core';
import { getValue } from '../../../state/settings/roomRecord';
import { useAppState } from '../../../state';
import { Steps } from '../PreJoinScreens';
import '../../../i18n/config';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
    gutterBottom: {
        marginBottom: '1em',
      },
}));

interface WaitingRoomScreenProps {
    setStep: (step: Steps) => void;
  }

export default function WaitingRoomScreen({ setStep }: WaitingRoomScreenProps){
    const classes = useStyles();
    const { t } = useTranslation();
    const { roomRecord } = useAppState();

    useEffect(() => {
        const timer = setTimeout(() => {
            setStep(Steps.roomNameStep)
          }, roomRecord?.start * 1000);
          return () => clearTimeout(timer);
    }, []);

    return (
    <>
        <Typography variant="h5" className={classes.gutterBottom}>
            {t('WaitingRoomScreen.waiting_room')}
        </Typography>
        <Typography variant="body1">
            {getValue(roomRecord?.name)}
            {getValue(roomRecord?.name) && <br></br>}
            {t('WaitingRoomScreen.start_from', {date: getValue(roomRecord?.date)})}
            <br></br>
            <br></br>
            {t('WaitingRoomScreen.please_wait')}
        </Typography>
    </>
    );
}