import i18n from 'i18next';
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from 'react-i18next';

import translation_en from './en/translation.json';
import translation_ja from './ja/translation.json';

export const resources = {
  en: {
    translation: translation_en
  },
  ja: {
    translation: translation_ja
  },
} as const;

i18n
    .use(detector)
    .use(initReactI18next).init({
        fallbackLng: "en",
        resources,
        interpolation: {
            escapeValue: false
        },
        detection: {
            caches: [],
        },
    });