import React, { useState } from 'react';
import { makeStyles, Typography, Grid, Button, Theme, Hidden } from '@material-ui/core';
import LocalVideoPreview from './LocalVideoPreview/LocalVideoPreview';
import SettingsMenu from './SettingsMenu/SettingsMenu';
import { Steps } from '../PreJoinScreens';
import ToggleAudioButton from '../../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../../Buttons/ToggleVideoButton/ToggleVideoButton';
import { useAppState } from '../../../state';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { getValue } from '../../../state/settings/roomRecord';

import '../../../i18n/config';
import { useTranslation } from 'react-i18next';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  marginTop: {
    marginTop: '1em',
  },
  deviceButton: {
    width: '100%',
    border: '2px solid #aaa',
    margin: '1em 0',
  },
  localPreviewContainer: {
    paddingRight: '2em',
    [theme.breakpoints.down('sm')]: {
      padding: '0 2.5em',
    },
  },
  joinButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      width: '100%',
      '& button': {
        margin: '0.5em 0',
      },
    },
  },
  mobileButtonBar: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '1.5em 0 1em',
    },
  },
  mobileButton: {
    padding: '0.8em 0',
    margin: 0,
  },
  errorMessage: {
    color: 'red',
    display: 'flex',
    alignItems: 'center',
    margin: '1em 0 0.2em',
    '& svg': {
      marginRight: '0.4em',
    },
  },
}));

interface DeviceSelectionScreenProps {
  name: string;
  roomName: string;
  refId: string;
  setStep: (step: Steps) => void;
}

export default function DeviceSelectionScreen({ name, roomName, refId, setStep }: DeviceSelectionScreenProps) {
  const classes = useStyles();
  const { isFetching, roomRecord, getToken, checkDuplicated, setRoomRecord } = useAppState();
  const { isAcquiringLocalTracks, isConnecting, connect } = useVideoContext();
  const [isCheck, setIsCheck] = useState<boolean>(false);
  const [isJoinError, setIsJoinError] = useState<boolean>(false);
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting || isCheck;

  const { t } = useTranslation();

  const handleJoin = () => {
    setIsCheck(true);
    setIsJoinError(false);
    checkDuplicated(name, roomName, roomRecord.type).then(
      result => {
        setIsCheck(false);
        setRoomRecord(result);
        if(result.end <= 0)
          setStep(Steps.notFoundStep);
        else
          getToken(name, roomName, refId).then(token => connect(token));
      }
    ).catch((error) => {
      setIsJoinError(true);
    });
  };

  return (
    <>
      {getValue(roomRecord?.name) && <Typography variant="h5" className={classes.gutterBottom}>
      {t('DeviceSelectionScreen.welcome_to', {name: getValue(roomRecord?.name)})}
      </Typography>}
      {!getValue(roomRecord?.name) && <Typography variant="h5" className={classes.gutterBottom}>
        {t('DeviceSelectionScreen.welcome')}
      </Typography>}

      <Grid container justify="center">
        <Grid item md={7} sm={12} xs={12}>
          <div className={classes.localPreviewContainer}>
            <LocalVideoPreview identity={name} />
          </div>
          <div className={classes.mobileButtonBar}>
            <Hidden mdUp>
              <ToggleAudioButton className={classes.mobileButton} disabled={disableButtons} />
              <ToggleVideoButton className={classes.mobileButton} disabled={disableButtons} />
            </Hidden>
            <SettingsMenu mobileButtonClass={classes.mobileButton} />
          </div>
        </Grid>
        <Grid item md={5} sm={12} xs={12}>
          <Grid container direction="column" justify="space-between" style={{ height: '100%' }}>
            <div>
              <Hidden smDown>
                <ToggleAudioButton className={classes.deviceButton} disabled={disableButtons} />
                <ToggleVideoButton className={classes.deviceButton} disabled={disableButtons} />
              </Hidden>
            </div>
            <div className={classes.joinButtons}>
              <Button variant="outlined" color="primary" onClick={() => setStep(Steps.roomNameStep)}>
              {t('DeviceSelectionScreen.button_cancel')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                data-cy-join-now
                onClick={handleJoin}
                disabled={disableButtons}
              >
                {t('DeviceSelectionScreen.button_join')}
              </Button>
            </div>
            <div>
              {
                isJoinError && (
                  <Typography variant="caption" className={classes.errorMessage}>
                  <ErrorOutlineIcon />
                  {t('PreJoinScreens.error_duplicated_name')}
                </Typography>
                )
              }
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
