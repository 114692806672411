import React, { useState, useRef, useEffect } from 'react';
import Moment from "moment";
import { FormControl, TextField, Paper, Grid, Divider, List, ListItem, ListItemText, Fab, Typography } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import { makeStyles } from '@material-ui/core/styles';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { Message } from '../../../state/settings/roomRecord';
import { useAppState } from '../../../state';
import '../../../i18n/config';
import { useTranslation } from 'react-i18next';
import anchorme from "anchorme";
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  chatSection: {
    width: '100%',
    height: '52vh'
  },
  headBG: {
      backgroundColor: '#e0e0e0'
  },
  borderRight500: {
      borderRight: '1px solid #e0e0e0'
  },
  messageArea: {
    minWidth: 430,
    height: '40vh',
    overflowY: 'auto'
  },
  messageColor: {
    color: '#777777'
  },
  paperMessage: {
    padding: theme.spacing(1),
    textAlign: 'left',
    backgroundColor: '#e2ffc7',
    maxWidth: 280
  },
}));

export default function ChatInput() {
  const [message, setMessage] = useState('');
  const { room } = useVideoContext();
  const classes = useStyles();
  const scrollRef = useRef<HTMLInputElement>(null);
  const { chatMessageList, setChatMessageList } = useAppState();
  const { t } = useTranslation();

  let identity = room.localParticipant.identity;
  if(identity.indexOf('%_%') > -1){
    identity = identity.substring(identity.indexOf('%_%') + 3);
  }

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({behavior: "smooth"});
    }
  }, [chatMessageList]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => setMessage(e.target.value);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    send();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.keyCode === 13 && event.ctrlKey) {
        send();
    }
  };

  const send = () => {
    if (message) {
      let mess = new Message(message, Moment().format('HH:mm'), identity);
      setChatMessageList([...chatMessageList, mess]);
      
      const [localDataTrackPublication] = [...room.localParticipant.dataTracks.values()];
      localDataTrackPublication.track.send(JSON.stringify(mess));

      setMessage('');
    }
  };

  const getAnchoredMessage = (m: string) => {
      return parse(anchorme({ 
        input: DOMPurify.sanitize(m, {ALLOWED_TAGS: []}),
        options: {
            attributes: {
                target: "_blank",
            },
        }
        }
      ).replace(/(?:\r\n|\r|\n)/g, '<br>'));
  }

  return (
    <form autoComplete="off" style={{ display: 'flex', alignItems: 'center' }} onSubmit={handleSubmit}>
      <div>
        <Grid container component={Paper} className={classes.chatSection}>
            <Grid item xs={12}>
                <List className={classes.messageArea}>
                  {chatMessageList.map((item, index) =>
                    {
                      if(item.name === identity)
                      {
                        return (
                        <ListItem key="1">
                          <Grid container direction="row" justify="flex-end">
                            <Grid item xs={4} />
                            <Grid item xs={8}>
                            <ListItemText
                              primary={
                                <Paper elevation={5} className={classes.paperMessage}>{getAnchoredMessage(item.message)}</Paper>
                              }
                              secondary={
                                <Typography component="div" align="right" className={classes.messageColor}>
                                  {item.date}
                                </Typography>
                              }>
                              </ListItemText>
                            </Grid>
                        </Grid>
                    </ListItem>)
                      }
                      else{
                        return (
                        <ListItem key="1">
                        <Grid container>
                            <Grid item xs={8}>
                                <ListItemText 
                                  primary={
                                    <Paper elevation={5} className={classes.paperMessage}>{getAnchoredMessage(item.message)}</Paper>
                                  }
                                  secondary={
                                    <Typography component="div" align="left" className={classes.messageColor}>
                                      {item.date} {t('ChatInput.from', { name: item.name})}
                                    </Typography>
                                  }>
                                  </ListItemText>
                            </Grid>
                            <Grid item xs={4} />
                        </Grid>
                    </ListItem>)
                      }
                    }
                  )}
                    
                    <div ref={scrollRef}></div>
                </List>
                <Divider />
                <Grid container style={{padding: '20px'}}>
                    <Grid item xs={11}>
                    <FormControl fullWidth><TextField value={message} autoFocus={true} onChange={handleChange} onKeyDown={handleKeyDown} id="chat-snack-input" fullWidth multiline rowsMax={2} /></FormControl>
                    </Grid>
                    <Grid item xs={1} justify="flex-end">
                        <Fab type="submit" color="primary" aria-label="add" disabled={!message}><SendIcon /></Fab>
                    </Grid>
                    <Typography>{t('ChatInput.remarks')}</Typography>
                </Grid>
            </Grid>
        </Grid>
      </div>
    </form>
  );
}