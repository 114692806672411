import { useEffect } from 'react';
import { DataTrack as IDataTrack } from 'twilio-video';
import { useAppState } from '../../state';

export default function DataTrack({ track }: { track: IDataTrack }) {
  const { chatMessageList, setChatMessageList, remoteMessageCount, setRemoteMessageCount } = useAppState();

  useEffect(() => {
    const handleMessage = (message: string) => {
      setChatMessageList([...chatMessageList, JSON.parse(message)]);
      setRemoteMessageCount(remoteMessageCount + 1);
    }

    track.on('message', handleMessage);
    return () => {
      track.off('message', handleMessage);
    };
  }, [track, chatMessageList, remoteMessageCount]);

  return null; // This component does not return any HTML, so we will return 'null' instead.
}