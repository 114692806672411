import React, { createContext, useContext, useReducer, useState } from 'react';
import { RoomType } from '../types';
import { TwilioError } from 'twilio-video';
import { settingsReducer, initialSettings, Settings, SettingsAction } from './settings/settingsReducer';
import useActiveSinkId from './useActiveSinkId/useActiveSinkId';
import useFirebaseAuth from './useFirebaseAuth/useFirebaseAuth';
import usePasscodeAuth from './usePasscodeAuth/usePasscodeAuth';
import { User } from 'firebase';
import { RoomRecord, Message } from './settings/roomRecord';

export interface StateContextType {
  error: TwilioError | null;
  setError(error: TwilioError | null): void;
  getToken(name: string, room: string, ref: string, passcode?: string): Promise<string>;
  checkDuplicated(name: string, room: string, type: string): Promise<RoomRecord>;
  getRoom(room: string, ref: string): Promise<RoomRecord>;
  checkPassword(room: string, password: string): Promise<RoomRecord>;
  user?: User | null | { displayName: undefined; photoURL: undefined; passcode?: string };
  signIn?(passcode?: string): Promise<void>;
  signOut?(): Promise<void>;
  isAuthReady?: boolean;
  isFetching: boolean;
  activeSinkId: string;
  setActiveSinkId(sinkId: string): void;
  settings: Settings;
  dispatchSetting: React.Dispatch<SettingsAction>;
  roomType?: RoomType;
  roomRecord: RoomRecord;
  setRoomRecord(roomRecord: RoomRecord): void;
  isEnd: boolean;
  setIsEnd(isEnd: boolean): void;
  message: string;
  setMessage(message: string): void;
  chatMessageList: Message[];
  setChatMessageList(chatMessageList: Message[]): void;
  remoteMessageCount: number;
  setRemoteMessageCount(remoteMessageCount: number): void;
}

export const StateContext = createContext<StateContextType>(null!);

/*
  The 'react-hooks/rules-of-hooks' linting rules prevent React Hooks fron being called
  inside of if() statements. This is because hooks must always be called in the same order
  every time a component is rendered. The 'react-hooks/rules-of-hooks' rule is disabled below
  because the "if (process.env.REACT_APP_SET_AUTH === 'firebase')" statements are evaluated
  at build time (not runtime). If the statement evaluates to false, then the code is not
  included in the bundle that is produced (due to tree-shaking). Thus, in this instance, it
  is ok to call hooks inside if() statements.
*/
export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
  const [error, setError] = useState<TwilioError | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [activeSinkId, setActiveSinkId] = useActiveSinkId();
  const [settings, dispatchSetting] = useReducer(settingsReducer, initialSettings);
  const [roomRecord, setRoomRecord] = useState<RoomRecord>();
  const [isEnd, setIsEnd] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [chatMessageList, setChatMessageList] = useState<Message[]>([]);
  const [remoteMessageCount, setRemoteMessageCount] = useState<number>(0);

  let contextValue = {
    error,
    setError,
    isFetching,
    activeSinkId,
    setActiveSinkId,
    settings,
    dispatchSetting,
    roomRecord,
    setRoomRecord,
    isEnd,
    setIsEnd,
    message,
    setMessage,
    chatMessageList,
    setChatMessageList,
    remoteMessageCount,
    setRemoteMessageCount
  } as StateContextType;

  if (process.env.REACT_APP_SET_AUTH === 'firebase') {
    contextValue = {
      ...contextValue,
      ...useFirebaseAuth(), // eslint-disable-line react-hooks/rules-of-hooks
    };
  } else if (process.env.REACT_APP_SET_AUTH === 'passcode') {
    contextValue = {
      ...contextValue,
      ...usePasscodeAuth(), // eslint-disable-line react-hooks/rules-of-hooks
    };
  } else {
    contextValue = {
      ...contextValue,
      getToken: async (identity, roomName, ref) => {
        const headers = new window.Headers();
        const endpoint = process.env.REACT_APP_ENDPOINT + "/twilio/gettoken" || '/token';
        const params = new window.URLSearchParams({ identity, roomName, "account_id": roomRecord?.account_id || '', "ref_id": ref });

        return fetch(`${endpoint}`, { headers, method: 'POST', body: params, credentials: 'include' }).then(res => res.text());
      },
      checkDuplicated: async (name, roomName, type) => {
        const headers = new window.Headers();
        const endpoint = process.env.REACT_APP_ENDPOINT + '/twilio/isduplicated';
        const params = new window.URLSearchParams({ "identity": name, "roomName": roomName, "account_id": roomRecord?.account_id || '', "type": type });
    
        return fetch(`${endpoint}`, { headers, method: 'POST', body: params, credentials: 'include' }).then(response => {
          if(response.ok)
            return response.json();
          else
            return Promise.reject('error');
        });
      },
      getRoom: async (roomName, ref) => {
        const headers = new window.Headers();
        const endpoint = process.env.REACT_APP_ENDPOINT + "/room/get";
        const params = new window.URLSearchParams({ "id": roomName, "ref_id": ref });

        return fetch(`${endpoint}`, { headers, method: 'POST', body: params, credentials: 'include' }).then(response => {
          if(response.ok)
            return response.json();
          else
            return Promise.reject('error');
        });
      },
      checkPassword: async (roomName, password) => {
        const headers = new window.Headers();
        const endpoint = process.env.REACT_APP_ENDPOINT + "/room/checkpassword";
        const params = new window.URLSearchParams({ "id": roomName, "password": password, "account_id": roomRecord?.account_id || '' });

        return fetch(`${endpoint}`, { headers, method: 'POST', body: params, credentials: 'include' }).then(response => {
          if(response.ok)
            return response.json();
          else
            return Promise.reject('error');
        });
      },
    };
  }

  const getToken: StateContextType['getToken'] = (name, room, ref) => {
    setIsFetching(true);
    return contextValue
      .getToken(name, room, ref)
      .then(res => {
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  return <StateContext.Provider value={{ ...contextValue, getToken }}>{props.children}</StateContext.Provider>;
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}
