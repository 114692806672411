import React from 'react';
import { FormControl, MenuItem, Typography, Select } from '@material-ui/core';
import { useAppState } from '../../../state';
import { useAudioOutputDevices } from '../../../hooks/deviceHooks/deviceHooks';

import '../../../i18n/config';
import { useTranslation } from 'react-i18next';

export default function AudioOutputList() {
  const audioOutputDevices = useAudioOutputDevices();
  const { activeSinkId, setActiveSinkId } = useAppState();
  const activeOutputLabel = audioOutputDevices.find(device => device.deviceId === activeSinkId)?.label;

  const { t } = useTranslation();

  return (
    <div className="inputSelect">
      {audioOutputDevices.length > 1 ? (
        <FormControl fullWidth>
          <Typography variant="subtitle2" gutterBottom>
            {t('AudioOutputList.audio_output')}
          </Typography>
          <Select onChange={e => setActiveSinkId(e.target.value as string)} value={activeSinkId} variant="outlined">
            {audioOutputDevices.map(device => (
              <MenuItem value={device.deviceId} key={device.deviceId}>
                {device.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <>
          <Typography variant="subtitle2">{t('AudioOutputList.audio_output')}</Typography>
          <Typography>{activeOutputLabel || t('AudioOutputList.default_audio')}</Typography>
        </>
      )}
    </div>
  );
}
