import React, { useState, useEffect } from 'react';

import ChatIcon from '@material-ui/icons/CommentOutlined';
import { Tooltip, Badge } from '@material-ui/core';

import ChatInput from './ChatInput';
import { Button, withStyles } from '@material-ui/core';
import { useAppState } from '../../../state';
import '../../../i18n/config';
import { useTranslation } from 'react-i18next';

const LightTooltip = withStyles({
  tooltip: {
    backgroundColor: 'white',
    maxWidth: 450,
  },
  arrow: {
    color: 'white',
  },
})(Tooltip);

export default function ChatButton() {
  const [isOpen, setIsOpen] = useState(false);
  const { remoteMessageCount, setRemoteMessageCount } = useAppState();
  const { t } = useTranslation();

  const onClick = () => {
    setIsOpen(isOpen => !isOpen);
    setRemoteMessageCount(0);
  }

  useEffect(() => {
      if(isOpen)
        setRemoteMessageCount(0);
  }, [isOpen, remoteMessageCount]);

  return (
    <LightTooltip title={<ChatInput />} interactive placement="top" arrow={true} open={isOpen}>
        <Badge badgeContent={remoteMessageCount} color="error">
        <Button onClick={() => onClick()} startIcon={<ChatIcon />}>
        {t('ChatButton.button_chat')}
        </Button>
        </Badge>
      </LightTooltip>
  );
}